import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import axios from "axios";
import App from "./app/index";

Bugsnag.start({
    apiKey: "c412b01b5104df079eaaab96f69dc0f0",
    plugins: [new BugsnagPluginReact(React)],
    notifyReleaseStages: ["production", "staging"],
});

const ErrorBoundary = Bugsnag.getPlugin("react");

axios.interceptors.response.use(res => {
    return res;
}, err => {
    if (err.response.status === 401) {
        window.location.href = "/login";
    }
    return Promise.reject(err);
});


ReactDOM.render(
    <ErrorBoundary>
        <App/>
    </ErrorBoundary>,
    document.getElementById("app"),
);
