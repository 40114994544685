import React, { Component } from "react";
import { Avatar, Button, Col, Pagination, Row, Table } from "antd";
import { paginateMedia, queryMedia, searchMedia } from "~app/actions/media";
import Search from "~app/components/filters/search";
import Translatable from "~app/hoc/translatable";
import Imagable from "~app/hoc/imagable";
import { compose } from "redux";
import { connect } from "react-redux";

const { Column } = Table;

class LinkMulti extends Component {
    constructor(props) {
        super(props);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.search = this.search.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.props.paginateMedia(0);
    }

    getActions() {
        const { model, group } = this.props;
        return [
            (medium) => <Button key="link" icon="link" className="create-button" onClick={() => {
                this.props.linkAction(medium, model, group);
                this.goBack();
            }
            }/>
        ];
    }

    onPaginationChange(page, pageSize) {
        this.props.paginateMedia((page - 1) * pageSize, pageSize);
    }

    async search(search) {
        this.props.searchMedia(search);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const { media, limit, totalCount, getImage, search, translation } = this.props;
        return (
            <>
                <Row gutter={[10, 40]}>
                    <Col>
                        <Search defaultValue={search} onChange={this.search}/>
                    </Col>
                    <Col>
                        <Pagination onChange={this.onPaginationChange} pageSize={limit} total={totalCount}/>
                        <Table dataSource={media} rowKey="id" bordered={true} pagination={false} className="mt20">
                            <Column key="thumb"
                                    render={(medium) => <Avatar shape="square" size={64} src={getImage(medium.uri)}
                                                                className="mr20"/>}></Column>
                            <Column title="Name" key="name" render={(medium) => translation(medium.name)}></Column>
                            <Column title="Copyright" key="copyright" render={(medium) => translation(medium.copyright)}></Column>
                            <Column title="Type" key="type" render={medium => <>{medium.type || "-"}</>}/>
                            <Column key="actions"
                                    render={(medium) => this.getActions()
                                        .map((action) => action(medium))}></Column>
                        </Table>
                    </Col>
                </Row>
                <Row gutter={[10, 40]}>
                    <Col>
                        <Button icon="rollback" onClick={this.goBack}>Back</Button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { group } = ownProps.match.params;
    return {
        totalCount: state.media.totalCount,
        skip: state.media.skip,
        limit: state.media.limit,
        media: state.media.models,
        group,
    };
};

const connectStore = connect(
    mapStateToProps,
    {
        queryMedia,
        paginateMedia,
        searchMedia,
    },
);

export default compose(connectStore, Imagable, Translatable)(LinkMulti);
