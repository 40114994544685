import React, { Component } from "react";

const getImage = function (uri) {
    if (uri) {
        return `/api/v1/media/download/${uri}`;
    }
    return null;
};

const resizeImage = function (uri, width, height) {
    if (uri) {
        return `https://stam-production.imgix.net/${encodeURI(uri)}?w=${width}&h=${height}`;
    }
};

function Imagable(WrappedComponent) {
    class ForwardComponent extends Component {
        render() {
            const { forwardedRef, ...restProps } = this.props;
            return <WrappedComponent getImage={getImage} resizeImage={resizeImage} ref={forwardedRef} {...restProps}/>;
        }
    }

    return React.forwardRef((props, ref) => {
        return <ForwardComponent {...props} forwardedRef={ref}/>;
    });
}

export default Imagable;
