import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router";
import List from "~app/views/media/list";
import View from "~app/views/media/view";
import LinkMultiWrapper from "~app/views/media/link-multi-wrapper";

class Index extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/media" component={List}/>
                <Route path="/media/link/:model/:id" component={LinkMultiWrapper}/>
                {/*<Route path="/media/:id/link" component={LinkWrapper}/>*/}
                <Route path="/media/create" component={View}/>
                <Route path="/media/:id" component={View}/>
            </Switch>
        );
    }
}

export default Index;
