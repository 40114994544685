import mediaReducer from "~app/reducers/media";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import languagesReducer from "~app/reducers/languages";
import interfaceTextsReducer from "~app/reducers/interface-texts";
import showcasesReducer from "~app/reducers/showcases";
import mediaGroupsReducer from "~app/reducers/media-groups";
import applicationsReducer from "~app/reducers/applications";
import objectsReducer from "~app/reducers/objects";
import organizationsReducer from  "~app/reducers/organizations";

const defaultReducer = (state = {}, action) => {
    if (action.type.includes("ERROR")) {
        console.log(action);
    }
    return state;
};

const reducer = (history) => combineReducers({
    defaultReducer,
    router: connectRouter(history),
    media: mediaReducer,
    mediaGroups: mediaGroupsReducer,
    languages: languagesReducer,
    interfaceTexts: interfaceTextsReducer,
    applications: applicationsReducer,
    showcases: showcasesReducer,
    objects: objectsReducer,
    organizations: organizationsReducer,
});

export default reducer;
