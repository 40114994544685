import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import List from "./list";
import Edit from "./edit";

export default class Index extends Component {
    render() {
        return (
                <Switch>
                    <Route exact path="/showcases" component={List}/>
                    <Route path="/showcases/create" component={Edit}/>
                    <Route path="/showcases/:id" component={Edit}/>
                </Switch>
        )
    }
}
