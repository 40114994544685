import ReactQuill from "react-quill";
import React from "react";

const modules = {
    toolbar: [
        ["bold", "italic"],
        [{ script: "sub" }, { script: "super" }],
        // ["clean"],
    ],
};

const TextEditor = ({ ...restProps }) => (
    <ReactQuill modules={modules} {...restProps} />
);

export default TextEditor;
