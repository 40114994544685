export const getMarkets = (state) => state.markets.models;

export const getCategories = (state) => state.categories.models;
export const getRegions = (state) => state.regions.models;
export const getTags = (state) => state.tags.models;
export const getLanguages = (state) => state.languages.models;
export const getMediaGroups = (state) => state.mediaGroups.models;
export const getUsers = (state) => state.users.models;
export const getMedia = (state) => state.media.models;
export const getPages = (state) => state.pages.models;
export const getProducts = (state) => state.products.models;
