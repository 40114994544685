import BaseModel from "~app/models/base-model";

class Medium extends BaseModel {
    constructor(model) {
        super(model);
        this.name = model && model.name || {};
        this.description = model && model.description || {};
        this.copyright = model && model.copyright || {};
        this.key = model && model.key || null;
        this.type = model && model.type || null;
        this.uri = model && model.uri || null;
        this.linksCount = model && model.linksCount || null;
        this.tags = model && model.tags || [];
    }
}

Medium.class = "Medium";
export default Medium;
