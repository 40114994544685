import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import v from "voca";
import Translatable from "~app/hoc/translatable";
import { Button, Icon, Input, Popconfirm, Table } from "antd";
import _ from "lodash";
import { compose } from "lodash/fp";
import { queryOrganizations, searchOrganizations } from "~app/actions/organizations";
import { connect } from "react-redux";

const { Column } = Table;
const { Search } = Input;

class List extends Component {
    constructor(props) {
        super(props);
        this.search = _.debounce(this.search.bind(this), 200);
    }

    async componentDidMount() {
        this.props.queryOrganizations();
    }

    async deleteOrganization(organization) {
        await axios.delete("/api/v1/organizations/" + organization.id);
        this.props.queryOrganizations();
    }

    async search(search) {
        this.props.searchOrganizations(search);
    }

    render() {
        const { t, currentTranslation, organizations } = this.props;
        return (
            <>
                <Search placeholder={v.capitalize(t("organization_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={organizations} rowKey={organization => organization.id} showSearch={true} className="mt20">
                    <Column key="name" title={v.titleCase(t("name_internal"))} render={organization => organization.internal || "-"}/>
                    <Column key="actions" className="buttons-group" title={v.titleCase(t("action_plural"))} render={organization => <><Link
                        to={{
                            pathname: `/organizations/${organization.id}`,
                            state: {
                                organization,
                            },
                        }}>
                        <Button icon="edit" className="edit-button"/>
                    </Link><Popconfirm title={v.capitalize(t("organization_delete"))} icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteOrganization(organization)}
                    ><Button icon="delete" className="delete-button"/>
                    </Popconfirm></>}/>
                </Table>
                <Link
                    to={{
                        pathname: "/organizations/create",
                    }}
                >
                    <Button icon="plus" className="create-button">{v.titleCase(t("organization_new"))} </Button>
                </Link>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        organizations: state.organizations.models,
    };
};

const connectStore = connect(
    mapStateToProps,
    {
        queryOrganizations,
        searchOrganizations,
    },
);

export default compose(connectStore, withRouter, Translatable)(List);
