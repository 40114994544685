import { createReducer } from "@reduxjs/toolkit";
import Actions from "~app/constants/actions";
import ModelReducer from "~app/modules/model-reducer";

const initialState = {
    models: [],
};

const languagesReducer = createReducer(initialState, {
    [Actions.Languages.Query]: ModelReducer.QueryModels(),
});

export default languagesReducer;
