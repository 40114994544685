import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import v from "voca";
import Translatable from "~app/hoc/translatable";
import { Button, Icon, Input, Popconfirm, Table } from "antd";
import _ from "lodash";
import { compose } from "lodash/fp";
import { queryObjects, searchObjects } from "~app/actions/objects";
import { connect } from "react-redux";

const { Column } = Table;
const { Search } = Input;

class List extends Component {
    constructor(props) {
        super(props);
        this.search = _.debounce(this.search.bind(this), 200);
    }

    async componentDidMount() {
        this.props.searchObjects();
    }

    async deleteObject(object) {
        await axios.delete("/api/v1/showcases/objects/" + object.id);
        this.props.queryObjects();
    }

    async search(search) {
        this.props.searchObjects(search);
    }

    render() {
        const { t, currentTranslation, objects } = this.props;
        return (
            <>
                <Search placeholder={v.capitalize(t("object_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={objects} rowKey={object => object.id} showSearch={true} className="mt20">
                    <Column key="internal" title={v.titleCase(t("name_internal"))} render={object => object.internal || "-"}/>
                    <Column key="title" title={v.titleCase(t("title"))} render={object => <div dangerouslySetInnerHTML={{ __html: currentTranslation(object.title) }}></div>}/>
                    <Column key="published" title={v.titleCase(t("published"))} render={object => object.published && <Icon type="eye"/> || <Icon type="eye-invisible"/>}/>
                    <Column key="media" title={v.titleCase(t("media"))} render={object => object.mediaLinks.length && <Icon type="check-circle"/> || <Icon type="close-circle"/>}/>
                    <Column key="showcase" title={v.titleCase(t("showcase"))} render={object => <>{object.showcase ? object.showcase.internal : "-"}</>}/>
                    <Column key="actions" className="buttons-group" title={v.titleCase(t("action_plural"))} render={object => <><Link
                        to={{
                            pathname: `/objects/${object.id}`,
                            state: {
                                object,
                            },
                        }}>
                        <Button icon="edit" className="edit-button"/>
                    </Link><Popconfirm title={v.capitalize(t("object_delete"))} icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteObject(object)}
                    ><Button icon="delete" className="delete-button"/>
                    </Popconfirm></>}/>
                </Table>
                <Link
                    to={{
                        pathname: "/objects/create",
                    }}
                >
                    <Button icon="plus" className="create-button">{v.titleCase(t("object_new"))} </Button>
                </Link>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        objects: state.objects.models,
    };
};

const connectStore = connect(
    mapStateToProps,
    {
        queryObjects,
        searchObjects,
    },
);

export default compose(connectStore, withRouter, Translatable)(List);
