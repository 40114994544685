import React, { Component } from "react";
import axios from "axios";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { Card, Form, Icon, Input, Tabs } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import v from "voca";
import { withRouter } from "react-router";
import compose from "lodash/fp/compose";
import WithMediaLinks from "~app/hoc/medialinks";
import Imagable from "~app/hoc/imagable";
import Translatable from "~app/hoc/translatable";
import SortableTableTransfer from "~app/components/sortabletabletransfer";
import TextEditor from "~app/components/text-editor";
import { getCurrentShowcase } from "~app/selectors/showcases";
import { createShowcase, queryShowcases, updateShowcase } from "~app/actions/showcases";
import { connect } from "react-redux";
import { queryObjects } from "~app/actions/objects";
import { getObjects } from "~app/selectors/objects";

const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const leftObjectColumns = function leftObjectColumns(t) {
    return [
        {
            key: "internal",
            title: v.titleCase(t("name_internal")),
            render: object => object.internal || "-",
        },
        {
            key: "showcase",
            title: v.titleCase(t("showcase")),
            render: object => {
                return object.showcase && object.showcase.internal || "-";
            },
        },
    ];
};

const rightObjectColumns = function rightObjectColumns(t) {
    return [
        {
            key: "internal",
            title: v.titleCase(t("name_internal")),
            render: object => <Link to={"/objects/" + object.id}>{object.internal || "-"}</Link>,
        },
        {
            key: "published",
            title: v.titleCase(t("published")),
            render: object => {
                return object.published && <Icon type="eye"/> || <Icon type="eye-invisible"/>;
            },
        },
        {
            key: "top",
            title: v.titleCase(t("object_top")),
            render: object => {
                return object.isTop && <Icon type="star"/>;
            },
        },
    ];
};

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showcase: null,
        };
        this.save = this.save.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onObjectsSort = this.onObjectsSort.bind(this);
        this.onObjectIdsChange = this.onObjectIdsChange.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    async componentDidMount() {
        this.props.queryShowcases();
        this.props.queryObjects();
    }

    async save() {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { showcase } = this.props;
                const data = {
                    ...showcase,
                    internal: values.internal,
                    name: values.name,
                    summary: values.summary,
                    description: values.description,

                };
                if (showcase.id) {
                    this.props.updateShowcase(data);
                } else {
                    this.props.createShowcase(data);
                }
                this.goBack();
            }
        });
    }

    async onObjectIdsChange(objectIds) {
        const { showcase } = this.props;
        const data = {
            ...showcase,
            objectIds,
        };
        if (showcase.id) {
            await this.props.updateShowcase(data);
        } else {
            await this.props.createShowcase(data);
        }
        await this.props.queryObjects();
        await this.props.queryShowcases();
    }

    async onObjectsSort(objectIds) {
        for (const objectId of objectIds) {
            const data = {
                object: {
                    position: objectIds.indexOf(objectId),
                },
            };
            await axios.patch(`/api/v1/showcases/objects/${objectId}`, data);
        }
        this.props.queryShowcases();
    }

    render() {
        const { t, form, languages, currentLanguage, translation, showcase, objects } = this.props;
        const { getFieldDecorator } = form;
        return (
            showcase
            && (
                <div>
                    <Form {...formItemLayout}>
                        <h1 className="title">{showcase.internal}</h1>
                        <Card title={v.titleCase(t("property_plural"))}>
                            <Form.Item label={v.titleCase(t("name_internal"))}>
                                {getFieldDecorator("internal", {
                                    initialValue: showcase.internal,
                                    rules: [
                                        {
                                            required: true,
                                            message: v.capitalize(t("error_required")),
                                        },
                                    ],
                                })(
                                    <Input placeholder={v.titleCase(t("name_internal"))}/>
                                )}
                            </Form.Item>
                        </Card>
                        <Tabs defaultActiveKey={currentLanguage} animated={false} className="mt20">
                            {languages.map(language => (
                                <TabPane tab={language.name} key={language.key}>
                                    <Card title={v.titleCase(t("content"))}>
                                        <Form.Item label={v.titleCase(t("title"))}>
                                            {getFieldDecorator("name[" + language.key + "]", {
                                                initialValue: showcase.name[language.key],
                                            })(
                                                <Input placeholder={v.titleCase(t("name"))}/>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("summary"))} extra={v.capitalize(t("summary"))}>
                                            {getFieldDecorator("summary[" + language.key + "]", {
                                                initialValue: showcase.summary[language.key] || null,
                                            })(<TextEditor
                                                placeholder={v.titleCase(t("summary"))}
                                            />)}
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("description"))} extra={v.capitalize(t("description"))}>
                                            {getFieldDecorator("description[" + language.key + "]", {
                                                initialValue: showcase.description[language.key] || null,
                                            })(<TextEditor
                                                placeholder={v.titleCase(t("description"))}
                                            />)}
                                        </Form.Item>
                                    </Card>
                                </TabPane>
                            ))}
                        </Tabs>
                        <Card title={v.titleCase(t("object_plural"))} className="mt20">
                            <Form.Item>
                                {showcase.objects &&
                                <SortableTableTransfer
                                    onSort={this.onObjectsSort}
                                    models={showcase.objects}
                                    titles={[v.titleCase(t("object_unselected_plural")), v.titleCase(t("object_selected_plural"))]}
                                    rowKey={object => object.id}
                                    dataSource={objects}
                                    targetKeys={showcase.objectIds}
                                    onChange={this.onObjectIdsChange}
                                    leftColumns={leftObjectColumns(t)}
                                    rightColumns={rightObjectColumns(t)}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return translation(option.title)
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) || option.internal && option.internal.toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                />
                                }
                            </Form.Item>
                        </Card>
                        <div className="field is-grouped is-pulled-right mt20">
                            <div className="control">
                                <button className="button is-success" onClick={this.save}>{v.titleCase(t("save"))}</button>
                            </div>
                            <div className="control">
                                <button className="button" onClick={this.goBack}>{v.titleCase(t("cancel"))}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            ) || <></>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showcase: getCurrentShowcase(state),
        objects: getObjects(state),
    };
};

const mapDispatchToProps = {
    queryObjects,
    queryShowcases,
    updateShowcase,
    createShowcase,
};

const connectStore = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(connectStore, WithMediaLinks, Imagable, Translatable, withRouter, Form.create({ name: "edit" }))(Edit);
