export default class Application {
    constructor(model) {
        this.id = (model && model.id) || null;
        this.internal = (model && model.internal) || null;
        this.showcases = (model && model.showcases) || [];
        this.showcaseIds = (this.showcases && this.showcases.map((showcase) => showcase.id)) || [];
        this.missionId = (model && model.missionId) || null;
        this.shouldSleep = (model && model.shouldSleep) || false;
        this.mediaLinks = (model && model.mediaLinks) || [];
    }
}
