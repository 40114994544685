import {createSelector} from "reselect"
import {createMatchSelector} from "connected-react-router";
import Medium from "~app/models/medium";

const viewRoute = "/media/:mediumId";
const viewMatch = createMatchSelector(viewRoute);
export const mediumIdSelector = (state) => viewMatch(state)?.params.mediumId;

const createRoute = "/media/create";
const createMatch = createMatchSelector(createRoute);
const mediumCreateSelector = (state) => createMatch(state)?.isExact;

export const getMedia = (state) => state.media.models;

export const getCurrentMedium = createSelector(
    [getMedia, mediumIdSelector, mediumCreateSelector],
    (media, mediumId, mediumCreate) => {
        if (mediumCreate) {
            return new Medium();
        }
        return media.find((medium) => medium.id === mediumId);
    },
);
