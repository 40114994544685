import { createReducer } from "@reduxjs/toolkit";
import Actions from "~app/constants/actions";
import ModelReducer from "~app/modules/model-reducer";

const initialState = {
    models: [],
    skip: null,
    limit: null,
    search: null,
    totalCount: null,
    count: null,
};

const objectsReducer = createReducer(initialState, {
    [Actions.Objects.Query]: ModelReducer.QueryModels(),
    [Actions.Objects.Search]: ModelReducer.SearchModels(),
    [Actions.Objects.Paginate]: ModelReducer.PaginateModels(),
    [Actions.Objects.Update]: ModelReducer.UpdateModel(),
    [Actions.Objects.Create]: ModelReducer.CreateModel(),
    [Actions.Objects.LinkMedia] : ModelReducer.LinkMedia(),
    [Actions.Objects.UnlinkMedia] : ModelReducer.UnlinkMedia(),
});

export default objectsReducer;
