import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "~app/reducers";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["currentLanguage"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history))));
export const persistor = persistStore(store);

export default store;
