import React, { Component } from "react";
import { Avatar, Button, Col, Icon, Menu, Pagination, Popconfirm, Row, Table } from "antd";
import { Link } from "react-router-dom";
import ListHeader from "~app/components/headers/list";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";
import Imagable from "~app/hoc/imagable";
import { connect } from "react-redux";
import { deleteMedium, paginateMedia, queryMedia, searchMedia } from "~app/actions/media";
import Search from "~app/components/filters/search";
import Downloadable from "~app/hoc/downloadable";
import ReactPlayer from "react-player";

const { Column } = Table;

class List extends Component {
    constructor(props) {
        super(props);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.search = this.search.bind(this);
        this.downloadMediaItem = this.downloadMediaItem.bind(this);
        this.createMedium = this.createMedium.bind(this);
        this.deleteMedium = this.deleteMedium.bind(this);
    }

    getLinkMenu(model) {
        return <Menu onClick={this.getOnLinkMenuClick(model)}>
            <Menu.Item key="markets">
                <Icon type="link"/>
                Link to Industries
            </Menu.Item>
            <Menu.Item key="applications">
                <Icon type="link"/>
                Link to Applications
            </Menu.Item>
            <Menu.Item key="categories">
                <Icon type="link"/>
                Link to Categories
            </Menu.Item>
        </Menu>;
    }

    async search(search) {
        this.props.searchMedia(search);
    }

    deleteMedium(medium) {
        this.props.deleteMedium(medium);
    }

    getOnLinkMenuClick(medium) {
        return (e) => {
            const { history } = this.props;
            switch (e.key) {
            case "markets": {
                history.push(`/media/${medium.id}/link/markets`);
                break;
            }
            case "applications": {
                history.push(`/media/${medium.id}/link/applications`);
                break;
            }
            case "categories": {
                history.push(`/media/${medium.id}/link/categories`);
                break;
            }
            }
        };
    }

    async componentDidMount() {
        this.props.paginateMedia(0, 20);
    }

    onPaginationChange(page, pageSize) {
        this.props.paginateMedia((page - 1) * pageSize, pageSize);
    }

    downloadMediaItem(mediaItem) {
        window.open(this.props.getDownloadLink(mediaItem.uri));
    }

    createMedium() {
        const { history } = this.props;
        history.push(`/media/create`);
    }

    render() {
        const { media, limit, totalCount, search, t } = this.props;
        const { translation, getImage, resizeImage } = this.props;
        return (
            <>
                <ListHeader singular="medium" plural="media" count={totalCount} onCreate={this.createMedium} showImport={false} showExport={false}/>
                <Row gutter={[10, 40]}>
                    <Col>
                        <Search defaultValue={search} onChange={this.search}/>
                    </Col>
                    <Col>
                        <Pagination onChange={this.onPaginationChange} pageSize={limit} total={totalCount}/>
                        <Table
                            className="mt20"
                            dataSource={media}
                            rowKey="id"
                            bordered={true}
                            pagination={false}
                        >
                            <Column
                                title="Name"
                                key="name"
                                render={medium => (
                                    <>
                                        <span className="mr20">
                                            {medium.type !== "video" && <Avatar shape="square" size={64} src={resizeImage(medium.uri, 64, 64)}/>}
                                            {medium.type === "video" && <ReactPlayer url={getImage(medium.uri)} width={64} height={64} controls={false}/>}
                                        </span>
                                        <Link
                                            to={{
                                                pathname: `/media/${medium.id}`,
                                                state: { medium },
                                            }}
                                        >
                                            {translation(medium.name) || medium.key}
                                        </Link>
                                    </>
                                )}
                            />
                            <Column title="Type" key="type" render={medium => <>{medium.type || "-"}</>}
                                    filters={[{
                                        text: "Image",
                                        value: "image",
                                    }, {
                                        text: "Video",
                                        value: "video",
                                    }]}/>
                            <Column title="Description" key="description" render={medium => <>{translation(medium.description)}</>}/>
                            <Column title="Copyright" key="copyright" render={medium => <>{translation(medium.copyright)}</>}/>
                            <Column
                                title="Actions"
                                key="actions"
                                className="buttons-group"
                                render={medium => (
                                    <>
                                        <Link
                                            to={{
                                                pathname: `/media/${medium.id}`,
                                                state: { medium }
                                            }}
                                        >
                                            <Button icon="edit" className="edit-button"/>
                                        </Link>
                                        <Popconfirm title={t("medium_delete")} icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteMedium(medium)}>
                                            <Button icon="delete" className="delete-button"/>
                                        </Popconfirm>
                                        {/*              <Button icon="download" onClick={() => this.downloadMediaItem(medium)}/>
                                        <Dropdown overlay={this.getLinkMenu(medium)}>
                                            <Button icon="link"/>
                                        </Dropdown>*/}
                                    </>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        totalCount: state.media.totalCount,
        skip: state.media.skip,
        limit: state.media.limit,
        media: state.media.models,
        search: state.media.search,
    };
};

const connectStore = connect(
    mapStateToProps,
    {
        queryMedia,
        searchMedia,
        paginateMedia,
        deleteMedium,
    },
);

export default compose(connectStore, Downloadable, Imagable, Translatable)(List);
