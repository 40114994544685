import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import List from "./list";
import Edit from "./edit";

export default class Index extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/applications" component={List}/>
                <Route path="/applications/create" component={Edit}/>
                <Route path="/applications/:id" component={Edit}/>
            </Switch>
        );
    }
}
