import { Avatar, Button, Table } from "antd";
import React, { Component } from "react";
import MediaGroupsFilters from "~app/hoc/filters/media-groups";
import { compose } from "redux";
import Translatable from "~app/hoc/translatable";
import { Link } from "react-router-dom";
import Imagable from "~app/hoc/imagable";

const { Column } = Table;

class Media extends Component {
    render() {
        const { t, media, mediaGroups, getImage, translation, linkMedia, unlinkMedia } = this.props;
        return <><Table
            className="mt20"
            dataSource={media}
            rowKey="id"
            bordered={true}
            pagination={true}
            {...this.props}
        >
            <Column
                title="Preview"
                key="preview"
                render={medium => (
                    <>
                        <Avatar shape="square" size={64} src={getImage(medium.uri)} className="mr20"/>
                        <Link
                            to={{
                                pathname: `/media/${medium.id}`,
                                state: { medium },
                            }}
                        >
                            {translation(medium.name) || "-"}
                        </Link>
                    </>
                )}
            />
            <Column title="Type" key="type" render={medium => <>{medium.type || "-"}</>}
                    filters={[{
                        text: "Image",
                        value: "image",
                    }, {
                        text: "Video",
                        value: "video",
                    }]}/>
            <Column title="Name" key="name" render={medium => <>{translation(medium.name) || "-"}</>}/>
            <Column title="Description" key="description" render={medium => <>{translation(medium.description) || "-"}</>}/>
            <Column title="Copyright" key="copyright" render={medium => <>{translation(medium.copyright) || "-"}</>}/>
            <Column
                title="Actions"
                key="actions"
                render={medium => (
                    <div className="buttons">
                        <Button icon="delete" onClick={unlinkMedia("content",medium.id)}/>
                    </div>
                )}
            />
        </Table>
            <Button icon="plus-circle" className="edit-button" onClick={linkMedia("content")}>Link Media</Button>
        </>;
    }
}

export default compose(Imagable, Translatable, MediaGroupsFilters)(Media);
