import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import v from "voca";
import Application from "~app/models/application";
import { Button, Icon, Input, Popconfirm, Table } from "antd";
import _ from "lodash";
import Translatable from "~app/hoc/translatable";
import { compose } from "lodash/fp";
import { connect } from "react-redux";
import { getApplications } from "~app/selectors/applications";
import { deleteApplication, queryApplications, searchApplications, updateApplication } from "~app/actions/applications";

const { Column } = Table;
const { Search } = Input;

class List extends Component {
    constructor(props) {
        super(props);
        this.search = _.debounce(this.search.bind(this), 200);
        this.deleteApplication = this.deleteApplication.bind(this);
        this.sleepApplication = this.sleepApplication.bind(this);
    }

    async componentDidMount() {
        this.props.searchApplications();
    }

    async search(search) {
       this.props.searchApplications(search);
    }

    deleteApplication(application){
        this.props.deleteApplication(application);
    }

    sleepApplication(application){
        const data = {
            ...application,
            shouldSleep: !application.shouldSleep,
        };
        this.props.updateApplication(data);
    }

    render() {
        const { t, applications } = this.props;
        return (
            <>
                <Search placeholder={v.capitalize(t("application_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={applications} rowKey={application => application.id} className="mt20">
                    <Column key="title" title={v.titleCase(t("application"))} render={application => application.internal}/>
                    <Column key="showcases" title={v.titleCase(t("showcase_count"))} render={application => <>{application.showcases.length}</>}/>
                    <Column key="actions" title={v.titleCase(t("action_plural"))} className="buttons-group" render={application =>
                        <>
                            <Link to={{
                                pathname: `/applications/${application.id}`,
                                state: {
                                    application,
                                },
                            }}>
                                <Button icon="edit" className="edit-button"/>
                            </Link>
                            <Popconfirm title={t("application_delete")} icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteApplication(application)}>
                                <Button icon="delete" className="delete-button"/>
                            </Popconfirm>&nbsp;
                            { !application.shouldSleep &&
                            <Popconfirm title={t("application_sleep")} icon={<Icon type="poweroff" style={{ color: "red" }}/>} onConfirm={() => this.sleepApplication(application)}>
                                <Button icon="poweroff" className="delete-button"/>
                            </Popconfirm>}
                            { application.shouldSleep &&
                            <Popconfirm title={t("application_wake")} icon={<Icon type="tablet" style={{ color: "blue" }}/>} onConfirm={() => this.sleepApplication(application)}>
                                <Button icon="tablet" className="edit-button"/>
                            </Popconfirm>}
                        </>}
                    />
                </Table>
                <Link
                    to={{
                        pathname: "/applications/create",
                    }}
                >
                    <Button icon="plus" className="create-button">{v.titleCase(t("application_new"))} </Button>
                </Link>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        applications: getApplications(state),
    };
};

const connectStore = connect(
    mapStateToProps,
    {
        queryApplications,
        updateApplication,
        searchApplications,
        deleteApplication,
    },
);

export default compose(connectStore, Translatable)(List);
