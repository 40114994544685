import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import v from "voca";
import { Button, Icon, Input, Popconfirm, Table } from "antd";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { getShowcases } from "~app/selectors/showcases";
import { deleteShowcase, queryShowcases, searchShowcases } from "~app/actions/showcases";

const { Search } = Input;
const { Column } = Table;

class List extends Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.deleteShowcase = this.deleteShowcase.bind(this);
    }

    componentDidMount() {
        this.props.searchShowcases();
    }

    search(search) {
        this.props.searchShowcases(search);
    }

    deleteShowcase(showcase){
        this.props.deleteShowcase(showcase);
    }

    render() {
        const { t, translation, showcases} = this.props;
        return (
            <>
                <Search placeholder={v.capitalize(t("showcase_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={showcases} rowKey={showcase => showcase.id} className="mt20">
                    <Column key="internal" title={v.titleCase(t("name_internal"))} render={showcase => showcase.internal || "-"}/>
                    <Column key="title" title={v.titleCase(t("title"))} render={showcase => translation(showcase.name)}/>
                    <Column key="application" title={v.titleCase(t("application"))} render={showcase => <>{showcase.application ? showcase.application.internal : "-"}</>}/>
                    <Column key="actions" className="buttons-group" title={v.titleCase(t("action_plural"))} render={showcase => <>
                        <Link to={{
                            pathname: `/showcases/${showcase.id}`,
                            state: {
                                showcase,
                            },
                        }}>
                            <Button icon="edit" className="edit-button"/>
                        </Link>
                        <Popconfirm title={t("showcase_delete")} icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteShowcase(showcase)}>
                            <Button icon="delete" className="delete-button"/>
                        </Popconfirm>
                    </>}/>
                </Table>
                <Link
                    to={{
                        pathname: "/showcases/create",
                    }}
                >
                    <Button icon="plus" className="create-button">{v.titleCase(t("showcase_new"))} </Button>
                </Link>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showcases: getShowcases(state),
    };
};

const mapDispatchToProps = {
    queryShowcases,
    searchShowcases,
    deleteShowcase,
};

const connectStore = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(connectStore, Translatable)(List);
