import React, { Component } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "rc-calendar/assets/index.css";
import "rc-time-picker/assets/index.css";
import InterfaceText from "~app/models/interfacetext";
import v from "voca";
import { Card, Form, Input, Tabs } from "antd";
import compose from "lodash/fp/compose";
import { withRouter } from "react-router";
import Translatable from "~app/hoc/translatable";

const { TabPane } = Tabs;

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interfacetext: null,
        };
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.goBack = this.goBack.bind(this);
    }


    onChange(value) {
        const { interfacetext } = this.state;
        interfacetext.value = value;
        this.setState({ interfacetext });
    }

    goBack() {
        this.props.history.goBack();
    }

    async componentDidMount() {
        this.setState({
            interfacetext: await this.getInterfaceText(),
        });
    }

    async getInterfaceText() {
        let interfacetext = this.props.location.state && this.props.location.state.interfacetext || null;
        if (interfacetext !== null) {
            return interfacetext;
        }
        const interfacetextId = this.props.match.params.id;
        if (interfacetextId) {
            const result = await axios.get("/api/v1/interfacetexts/" + interfacetextId);
            return result.data;
        }
        interfacetext = new InterfaceText();
        return interfacetext;
    }

    async save() {
        const { interfacetext } = this.state;
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                interfacetext.key = values.key;
                interfacetext.value = values.value;
                const data = {
                    interfaceText: interfacetext,
                };
                await axios.post(interfacetext.id ? "/api/v1/interfacetexts/" + interfacetext.id : "/api/v1/interfacetexts", data);
                this.goBack();
            }
        });
    }

    render() {
        const { t, form, languages, currentLanguage, currentTranslation } = this.props;
        const { interfacetext } = this.state;
        const { getFieldDecorator } = form;

        return (
            interfacetext
            && (
                <Form>
                    <h1 className="title">{interfacetext.key}</h1>
                    <div className="mt20">
                        <Form.Item label={v.titleCase(t("key"))}>
                            {getFieldDecorator("key", {
                                initialValue: interfacetext.key,
                                rules: [
                                    {
                                        required: true,
                                        message: "Please fill in a translation key.",
                                    },
                                    {
                                        pattern: new RegExp("^[a-z.]*$"),
                                        message: "The translation key must be in lowercase dot notation format.",
                                    },
                                ],
                            })(
                                <Input name="key"/>
                            )}
                        </Form.Item>
                    </div>
                    <Tabs defaultActiveKey={currentLanguage} animated={false}>
                        {languages.map(language => (
                            <TabPane tab={language.name} key={language.key}>
                                <Card>
                                    <Form.Item label={v.titleCase(t("value"))}>
                                        {getFieldDecorator("value[" + language.key + "]", {
                                            initialValue: interfacetext.value[language.key],
                                            rules: [
                                                {
                                                    required: language.key === "nl-BE",
                                                    message: "Please fill in a translation value",
                                                },
                                            ],
                                        })(
                                            <Input placeholder={v.titleCase(t("value"))}/>
                                        )}
                                    </Form.Item>
                                </Card>
                            </TabPane>
                        ))}
                    </Tabs>
                    <div className="field is-grouped mt20">
                        <div className="control">
                            <button className="button is-success" onClick={this.save}>{t("save")}</button>
                        </div>
                        <div className="control">
                            <button className="button" onClick={this.goBack}>{t("cancel")}</button>
                        </div>
                    </div>
                </Form>
            )
        );
    }
}

export default compose(Translatable, Form.create({ name: "edit" }))(Edit);
