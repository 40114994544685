import React, { Component } from "react";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";
import { queryLanguages } from "~app/actions/languages";
import { connect } from "react-redux";
import { Button, Tree, Typography } from "antd";
import { queryApplications } from "~app/actions/applications";
import { queryShowcases } from "~app/actions/showcases";
import { queryObjects } from "~app/actions/objects";
import { getApplications } from "~app/selectors/applications";
import { getShowcases } from "~app/selectors/showcases";
import { getObjects } from "~app/selectors/objects";
import { getLanguages } from "~app/selectors/base";
import axios from "axios";

const { TreeNode } = Tree;
const { Title } = Typography;

class Index extends Component {

    componentDidMount() {
        this.props.queryLanguages();
        this.props.queryApplications();
        this.props.queryShowcases();
        this.props.queryObjects();
    }

    async exportShowcaseObjects(){
        const response = await axios.get("/api/v1/showcases/objects/export", {
            responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "STAM Artwork Export.xlsx");
        document.body.appendChild(link);
        link.click();
    }

    render() {
        const { applications, showcases, objects, translation } = this.props;
        return (
            <>
                <Title>Structuur</Title>
                <Button onClick={this.exportShowcaseObjects}>Export Artwork</Button>
                <Tree
                    showLine={true}
                    showIcon={false}
                    defaultExpandedKeys={[]}
                >
                    {applications.map((application) =>
                        <TreeNode title={application.internal} key={application.id}>
                            {showcases.filter((showcase) => showcase.applicationId === application.id)
                                .map((showcase) =>
                                    <TreeNode title={`${showcase.internal} - ${translation(showcase.name)}`} key={showcase.id}>
                                        {objects.filter((object) => object.showcaseId === showcase.id)
                                            .map((object) =>
                                                <TreeNode title={`${object.internal} - ${translation(object.title)} (${object.mediaLinks.length})`} key={object.id}/>
                                            )}
                                    </TreeNode>
                                )}
                        </TreeNode>
                    )}
                </Tree>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: getLanguages(state),
        applications: getApplications(state),
        showcases: getShowcases(state),
        objects: getObjects(state),
    };
};

const mapDispatchToProps = {
    queryLanguages,
    queryApplications,
    queryShowcases,
    queryObjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(compose(Translatable)(Index));
