import React, { Component } from "react";
import { Card, Checkbox, Col, DatePicker, Form, Icon, Input, Row, Select, Tabs } from "antd";
import TitleEditor from "~app/components/title-editor";
import "react-quill/dist/quill.snow.css";
import v from "voca";
import { connect } from "react-redux";
import { queryShowcases } from "~app/actions/showcases";
import Translatable from "~app/hoc/translatable";
import Imagable from "~app/hoc/imagable";
import WithMediaLinks from "~app/hoc/medialinks";
import compose from "lodash/fp/compose";
import moment from "moment";
import Media from "~app/components/media";
import { queryMediaGroups } from "~app/actions/media-groups";
import { getCurrentObject } from "~app/selectors/objects";
import { createObject, queryObjects, updateObject } from "~app/actions/objects";
import Sortable from "~app/hoc/sortable";
import { queryOrganizations } from "~app/actions/organizations";

const { TabPane } = Tabs;
const { Option } = Select;

const SortableMedia = Sortable(Media);

class Edit extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.linkMedia = this.linkMedia.bind(this);
        this.unlinkMedia = this.unlinkMedia.bind(this);
        this.state = { object: this.props.object };
        this.sortMedia = this.sortMedia.bind(this);
    }

    async componentDidMount() {
        this.props.queryShowcases();
        this.props.queryMediaGroups();
        this.props.queryObjects();
        this.props.queryOrganizations();
    }

    componentDidUpdate(prevProps, prevState) {
        const { object } = this.props;
        if (prevState.object !== object) {
            this.setState({
                object,
            });
        }
    }

    async save() {
        const { form, goBack } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { object } = this.state;
                if (object.id) {
                    this.props.updateObject({
                        ...object,
                        internal: values.internal,
                        link: values.link,
                        inventory: values.inventory,
                    });
                } else {
                    this.props.createObject({
                        ...object,
                        internal: values.internal,
                        link: values.link,
                        inventory: values.inventory,
                    });
                }
                goBack();
            }
        });
    }

    linkMedia(group) {
        return () => {
            const { history } = this.props;
            const { object } = this.props;
            history.push(`/media/link/object/${object.id}/${group}`, {});
        };
    }

    unlinkMedia(group, mediumId) {
        return () => {
            const object = { ...this.props.object };
            object.mediaLinks = object.mediaLinks.filter((medium) => !(medium.group === group && medium.id === mediumId));
            console.log(object.mediaLinks.length);
            this.props.updateObject(object);
        };
    }

    sortMedia(mediaIds) {
        const { object, updateObject } = this.props;
        const mediaLinks = [];
        for (const mediaLink of object.mediaLinks) {
            const position = mediaIds.findIndex((mediaId) => mediaId === mediaLink.id);
            if (position !== -1) {
                mediaLinks[position] = mediaLink;
            }
        }
        updateObject({
            ...object,
            mediaLinks,
        });
    }

    render() {
        const { t, showcases, languages, currentTranslation, goBack, form, currentLanguage, mediaGroups, object, organizations } = this.props;
        const { stateObject = object } = this.state;
        const { getFieldDecorator } = form;
        const onChange = this.props.onChange.bind(this);
        return (
            <>
                {stateObject && object && (
                    <Form>
                        <h1 className="title">{object.internal} {object.published && (<Icon type="eye"/> || <Icon type="eye-invisible"/>)}</h1>
                        <Card title={v.titleCase(t("property_plural"))}>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={v.titleCase(t("name_internal"))}>
                                        {getFieldDecorator("internal", {
                                            initialValue: object.internal,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: v.capitalize(t("error_required")),
                                                },
                                            ],
                                        })(
                                            <Input placeholder={v.titleCase(t("name_internal"))}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Checkbox defaultChecked={object.isOnLoan}
                                                  onChange={e => onChange("object", "isOnLoan", e.target.checked)}>{v.capitalize(t("on_loan"))}</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Checkbox defaultChecked={object.alwaysPublished}
                                                  onChange={e => onChange("object", "alwaysPublished", e.target.checked)}>{v.capitalize(t("published_always"))}</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {!object.alwaysPublished &&
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={12}>
                                    <Form.Item label={v.capitalize(t("published_from"))}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            showTime={false}
                                            format="DD/MM/YYYY"
                                            placeholder={v.capitalize(t("published_from"))}
                                            allowClear={true}
                                            defaultValue={object.startPublishingDate ? moment(object.startPublishingDate) : null}
                                            onChange={date => onChange("object", "startPublishingDate", date ? date.toISOString() : null)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={v.capitalize(t("published_until"))}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            showTime={false}
                                            format="DD/MM/YYYY"
                                            placeholder={v.capitalize(t("published_until"))}
                                            allowClear={true}
                                            defaultValue={object.endPublishingDate ? moment(object.endPublishingDate) : null}
                                            onChange={date => onChange("object", "endPublishingDate", date ? date.toISOString() : null)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>}
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={v.titleCase(t("showcase"))}>
                                        <Select defaultValue={object.showcaseId} style={{ width: "100%" }} onChange={value => onChange("object", "showcaseId", value)}>
                                            <Option title={v.capitalize(t("showcase_not_selected"))} key={null} value={null}>{v.capitalize(t("showcase_not_selected"))} </Option>
                                            {showcases.map(showcase => (
                                                <Option title={showcase.internal} key={showcase.id} value={showcase.id}>{showcase.internal}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={v.titleCase(t("organization"))}>
                                        <Select defaultValue={object.organizationId} style={{ width: "100%" }} onChange={value => onChange("object", "organizationId", value)}>
                                            <Option title={v.capitalize(t("organization_not_selected"))} key={null} value={null}>{v.capitalize(t("organization_not_selected"))} </Option>
                                            {organizations.map(organization => (
                                                <Option title={organization.internal} key={organization.id} value={organization.id}>{organization.internal}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={v.titleCase(t("inventory"))}>
                                        {getFieldDecorator("inventory", {
                                            initialValue: object.inventory,
                                        })(
                                            <Input placeholder={v.titleCase(t("inventory"))}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={t("link")}>
                                        {getFieldDecorator("link", {
                                            initialValue: object.link,
                                        })(
                                            <Input placeholder={t("link")}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Tabs defaultActiveKey={currentLanguage} animated={false} className="mt20">
                            {languages.map(language => (
                                <TabPane tab={language.name} key={language.key}>
                                    <Card title={v.titleCase(t("content"))}>
                                        <Form.Item label={v.titleCase(t("title"))}>
                                            <TitleEditor placeholder={v.titleCase(t("title"))} defaultValue={object.title[language.key]}
                                                         onChange={value => onChange("object", "title", value, language.key)}/>
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("description"))}>
                                            <TitleEditor placeholder={v.titleCase(t("description"))} defaultValue={object.description[language.key]}
                                                         onChange={value => onChange("object", "description", value, language.key)}/>
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("creator"))}>
                                            <TitleEditor placeholder={v.titleCase(t("creator"))} defaultValue={object.creator[language.key]}
                                                         onChange={value => onChange("object", "creator", value, language.key)}/>
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("dating"))}>
                                            <TitleEditor placeholder={v.titleCase(t("dating"))} defaultValue={object.dating[language.key]}
                                                         onChange={value => onChange("object", "dating", value, language.key)}/>
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("material"))}>
                                            <TitleEditor placeholder={v.titleCase(t("material"))} defaultValue={object.material[language.key]}
                                                         onChange={value => onChange("object", "material", value, language.key)}/>
                                        </Form.Item>
                                    </Card>
                                </TabPane>
                            ))}
                        </Tabs>
                        {object.id &&
                        <SortableMedia models={object.mediaLinks} onSort={this.sortMedia} media={object.mediaLinks}
                                       mediaGroups={mediaGroups}
                                       linkMedia={this.linkMedia}
                                       unlinkMedia={this.unlinkMedia}
                        />
                        }
                        <div className="field is-grouped is-pulled-right mt20">
                            <div className="control">
                                <button className="button is-success" onClick={this.save}>{v.titleCase(t("save"))}</button>
                            </div>
                            <div className="control">
                                <button className="button" onClick={goBack}>{v.titleCase(t("cancel"))}</button>
                            </div>
                        </div>
                    </Form>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        object: getCurrentObject(state),
        showcases: state.showcases.models,
        organizations: state.organizations.models,
        mediaGroups: state.mediaGroups.models,
    };
};

export default compose(WithMediaLinks, Imagable, Translatable, Form.create({ name: "edit" }), connect(mapStateToProps, {
    queryObjects,
    queryShowcases,
    queryMediaGroups,
    queryOrganizations,
    updateObject,
    createObject,
}))(Edit);
