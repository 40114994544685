const APPLICATIONS = "APPLICATIONS";
const ORGANIZATIONS = "ORGANIZATIONS";
const SHOWCASES = "SHOWCASES";
const OBJECTS = "OBJECTS";
const MEDIA = "MEDIA";
const LANGUAGES = "LANGUAGES";
const INTERFACE_TEXTS = "INTERFACE_TEXTS";
const MEDIA_GROUPS = "MEDIA_GROUPS";

class BaseActions {
    separator = "_";

    constructor(group) {
        this.group = group;
    }
}

class ModelActions extends BaseActions {
    constructor(group) {
        super(group);
    }

    get Create() {
        return this.createAction("CREATE");
    }

    get Update() {
        return this.createAction("UPDATE");
    }

    get Search() {
        return this.createAction("SEARCH");
    }

    get Query() {
        return this.createAction("QUERY");
    }

    get Paginate() {
        return this.createAction("PAGINATE");
    }

    get Sort() {
        return this.createAction("SORT");
    }

    get Delete() {
        return this.createAction("DELETE");
    }

    createAction(action) {
        const { group, separator } = this;
        return group + separator + action;
    }
}

class MediaModelActions extends ModelActions {
    constructor(group) {
        super(group);
    }

    get UnlinkMedia() {
        return this.createAction("UNLINK_MEDIA");
    }

    get LinkMedia() {
        return this.createAction("LINK_MEDIA");
    }
}

class LanguageModelActions extends ModelActions {
    constructor(group) {
        super(group);
    }

    get ChangeCurrentLanguage() {
        return this.createAction("CHANGE_CURRENT_LANGUAGE");
    }
}

class Actions {
    static get Applications() {
        return new ModelActions(APPLICATIONS);
    }

    static get Organizations() {
        return new ModelActions(ORGANIZATIONS);
    }


    static get Showcases() {
        return new ModelActions(SHOWCASES);
    }

    static get Objects() {
        return new MediaModelActions(OBJECTS);
    }

    static get Media() {
        return new ModelActions(MEDIA);
    }

    static get Languages() {
        return new LanguageModelActions(LANGUAGES);
    }

    static get InterfaceTexts() {
        return new ModelActions(INTERFACE_TEXTS);
    }

    static get MediaGroups() {
        return new ModelActions(MEDIA_GROUPS);
    }

    static Error(action) {
        return `${action}_ERROR`;
    }
}

export default Actions;
