import Actions from "~app/constants/actions";
import API from "~app/modules/api";
import Object from "~app/models/object";
import _ from "lodash";
import axios from "axios";
import { queryApplications } from "~app/actions/applications";

export const queryObjects = () => async (dispatch, getState) => {
    try {
        const {search, skip, limit} = getState().objects;
        const result = await API.QueryObjects(search, skip, limit);
        dispatch({
            type: Actions.Objects.Query,
            payload: {
                totalCount: result.data.metadata.totalCount,
                count: result.data.metadata.count,
                models: result.data.results.map((item) => new Object(item)),
            },
        });
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Objects.Query),
            payload: err,
        });
    }
};

export const paginateObjects = (skip, limit = 20) => async (dispatch) => {
    dispatch({
        type: Actions.Objects.Paginate,
        payload: {
            skip,
            limit,
        },
    });
    dispatch(queryObjects());
};

export const searchObjects = (search) => (dispatch) => {
    dispatch({
        type: Actions.Objects.Search,
        payload: {
            search,
        },
    });
    dispatch(paginateObjects(0));
    dispatch(queryObjects());
};

export const updateObject = (showcaseObject) => async (dispatch) => {
    try {
        const data = {
            showcaseObject,
        };
        const result = await axios.post(`/api/v1/showcases/objects/${showcaseObject.id}`, data);
        const model = new Object(result.data);
        dispatch({
            type: Actions.Objects.Update,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Objects.Update),
            payload: err,
        });
    }
};

export const createObject = (showcaseObject) => async (dispatch) => {
    try {
        const data = {
            showcaseObject,
        };
        const result = await axios.post(`/api/v1/showcases/objects`, data);
        const model = new Object(result.data);
        dispatch({
            type: Actions.Objects.Create,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Objects.Create),
            payload: err,
        });
    }
};

export const linkMediaToObject = (medium, model, group) => _.throttle(async (dispatch) => {
    try {
        const data = {
            media:
                [medium],
            group,
        };
        console.log(model);
        await axios.post(`/api/v1/showcases/objects/${model.id}/media/link`, data);
        dispatch({
            type: Actions.Objects.LinkMedia,
            payload: {medium, model, group},
        });
        return true;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Objects.LinkMedia),
            payload: err,
        });
        return false;
    }
}, 100);

export const unlinkMediaFromObject = (medium, model, group) => _.throttle(async (dispatch) => {
    try {
        const data = {
            media:
                [medium],
            group,
        };
        await axios.post(`/api/v1/showcases/objects/${model.id}/media/unlink`, data);
        dispatch({
            type: Actions.Objects.UnlinkMedia,
            payload: {medium, model, group},
        });
        return true;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Objects.UnlinkMedia),
            payload: err,
        });
        return false;
    }
}, 100);

export const deleteObject = (object) => async(dispatch) => {
    try {
        const result = await axios.delete(`/api/v1/showcases/objects/${object.id}`);
        dispatch({
            type: Actions.Objects.Delete,
            payload: result.data,
        });
        dispatch(queryObjects());
        return true;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Objects.Delete),
            payload: err,
        });
        return false;
    }
};
