import React, {Component} from "react";

const filterMediaGroupsByModel = (mediaGroups, modelName) => {
   return mediaGroups.filter(mediaGroup => mediaGroup.models.includes(modelName));
};

function MediaGroupsFilters(WrappedComponent) {
    class ForwardComponent extends Component {
        render() {
            const {forwardedRef, ...restProps} = this.props;
            return <WrappedComponent ref={forwardedRef} filterMediaGroupsByModel={filterMediaGroupsByModel} {...restProps} />;
        }
    }

    return React.forwardRef((props, ref) => {
        return <ForwardComponent {...props} forwardedRef={ref}/>;
    });
}

export default MediaGroupsFilters;
