import React, { Component } from "react";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";

const sanitizeHtml = require("sanitize-html");

function getFilesFromMediaLinks(mediaLinks, group) {
    const { getImage, resizeImage, currentTranslation } = this.props;
    return mediaLinks.filter(mediaLink => mediaLink.group === group)
        .map(mediaLink => ({
            uid: mediaLink.uri,
            name: mediaLink.description && sanitizeHtml(currentTranslation(mediaLink.description), { allowedTags: [] }) || "-",
            status: "done",
            url: getImage(mediaLink.uri),
            thumbUrl: resizeImage(mediaLink.uri, 86, 86),
        }));
}

function getFileFromMediaLink(mediaLink) {
    const { getImage, resizeImage } = this.props;
    return {
        uid: mediaLink.uri,
        name: mediaLink.uri,
        status: "done",
        url: getImage(mediaLink.uri),
        thumbUrl: resizeImage(mediaLink.uri, 86, 86),
    };
}

function syncFilesToMediaLinks(mediaLinks, files, group) {
    const newMediaLinks = mediaLinks.filter(mediaLink => mediaLink.group !== group);
    files.forEach(file => {
        if (file.status === "done") {
            if (file.response) {
                newMediaLinks.push({
                    uri: file.response.uri,
                    group,
                });
            } else {
                newMediaLinks.push({
                    uri: file.url,
                    group,
                });
            }
        }
    });
    return newMediaLinks;
}

function WithMediaLinks(WrappedComponent) {
    class WrapperComponent extends Component {
        render() {
            return <WrappedComponent getFilesFromMediaLinks={getFilesFromMediaLinks} syncFilesToMediaLinks={syncFilesToMediaLinks} getFileFromMediaLink={getFileFromMediaLink} {...this.props} />;
        }
    };
    return compose(Translatable)(WrapperComponent);
}

export default WithMediaLinks;
