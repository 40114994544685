import { createReducer } from "@reduxjs/toolkit";
import Actions from "~app/constants/actions";
import ModelReducer from "~app/modules/model-reducer";

const initialState = {
    models: [],
    skip: null,
    limit: null,
    search: null,
    totalCount: null,
    count: null,
};

const mediaReducer = createReducer(initialState, {
    [Actions.Media.Query]: ModelReducer.QueryModels(),
    [Actions.Media.Search]: ModelReducer.SearchModels(),
    [Actions.Media.Paginate]: ModelReducer.PaginateModels(),
    [Actions.Media.Update]: ModelReducer.UpdateModel(),
});

export default mediaReducer;
