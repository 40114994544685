import axios from "axios";
import Actions from "~app/constants/actions";
import API from "~app/modules/api";
import Medium from "~app/models/medium";
import { mediumIdSelector } from "~app/selectors/media";
import { queryApplications } from "~app/actions/applications";

export const getMedium = () => async (dispatch, getState) => {
    const mediumId = mediumIdSelector(getState())
    const result = await axios.get(`/api/v1/media/${mediumId}`);
    const medium = new Medium(result.data);
    dispatch({
        type: Actions.Media.Query,
        payload: {
            totalCount: 1,
            count: 1,
            models: [medium],
        },
    });
};

export const queryMedia = () => async (dispatch, getState) => {
    try {
        const { search, skip, limit } = getState().media;
        const result = await API.QueryMedia(search, skip, limit);
        dispatch({
            type: Actions.Media.Query,
            payload: {
                totalCount: result.data.metadata.totalCount,
                count: result.data.metadata.count,
                models: result.data.results.map((item) => new Medium(item)),
            },
        });
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Media.Query),
            payload: err,
        });
    }
};

export const paginateMedia = (skip, limit = 20) => async (dispatch) => {
    dispatch({
        type: Actions.Media.Paginate,
        payload: {
            skip,
            limit,
        },
    });
    dispatch(queryMedia());
};

export const searchMedia = (search) => (dispatch) => {
    dispatch({
        type: Actions.Media.Search,
        payload: {
            search,
        },
    });
    dispatch(paginateMedia(0));
    dispatch(queryMedia());
};

export const updateMedium = (medium) => async (dispatch) => {
    try {
        const data = {
            medium,
        };
        const result = await axios.post(`/api/v1/media/${medium.id}`, data);
        const model = new Medium(result.data);
        dispatch({
            type: Actions.Media.Update,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Media.Update),
            payload: err,
        });
    }
};

export const createMedium = (medium) => async (dispatch) => {
    try {
        const data = {
            medium,
        };
        const result = await axios.post("/api/v1/media/", data);
        const model = new Medium(result.data);
        dispatch({
            type: Actions.Media.Create,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Media.Create),
            payload: err,
        });
    }
};

export const deleteMedium = (medium) => async(dispatch) => {
    try {
        const result = await axios.delete(`/api/v1/media/${medium.id}`);
        dispatch({
            type: Actions.Media.Delete,
            payload: result.data,
        });
        dispatch(queryMedia());
        return true;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Media.Delete),
            payload: err,
        });
        return false;
    }
};
