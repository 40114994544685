import { createReducer } from "@reduxjs/toolkit";
import Actions from "~app/constants/actions";
import ModelReducer from "~app/modules/model-reducer";

const initialState = {
    models: [],
    skip: null,
    limit: null,
    search: null,
    totalCount: null,
    count: null,
};

const interfaceTextsReducer = createReducer(initialState, {
    [Actions.InterfaceTexts.Query]: ModelReducer.QueryModels(),
    [Actions.InterfaceTexts.Search]: ModelReducer.SearchModels(),
    [Actions.InterfaceTexts.Paginate]: ModelReducer.PaginateModels(),
    [Actions.InterfaceTexts.Update]: ModelReducer.UpdateModel(),
    [Actions.InterfaceTexts.Create]: ModelReducer.CreateModel(),
});

export default interfaceTextsReducer;
