import { Redirect, Route } from "react-router-dom";
import React from "react";

const checkAuth = async () => {
    return true;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            checkAuth()
                ? <Component {...props} />
                : (
                    <Redirect to={{
                        pathname: "/login",
                        state: { from: props.location },
                    }}
                    />
                )
        )}
    />
);

export default PrivateRoute;
