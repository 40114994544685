import React from "react";
import TextEditor from "./text-editor";

const modules = {
    toolbar: [
        ["bold", "italic"],
        [{ script: "sub" }, { script: "super" }],
        // ["clean"],
    ],
};

const TitleEditor = ({ ...restProps }) => (
    <TextEditor modules={modules} {...restProps} />
);

export default TitleEditor;
