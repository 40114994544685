import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Applications from "./views/applications";
import Media from "./views/media";
import Showcases from "./views/showcases";
import Objects from "./views/objects";
import InterfaceTexts from "./views/interfacetexts";
import Organizations from "./views/organizations";
import Dashboard from "./views/dashboard";
import Navigation from "~app/components/navigation";
import "~app/styles/index.scss";
import "~bulma-extensions";
import "~fontawesome";
import "~app/translations";
import { withTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { persistor, store, history } from "~app/store";
import { PersistGate } from "redux-persist/integration/react";
import { BackTop, Spin } from "antd";
import Login from "~app/views/login";
import { CookiesProvider, withCookies } from "react-cookie";
import PrivateRoute from "~app/components/private-route";
import { ConnectedRouter } from "connected-react-router";

class App extends Component {
    render() {
        return (
            <CookiesProvider>
                <Provider store={store}>
                    <PersistGate loading={<Spin/>} persistor={persistor}>
                        <BackTop/>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route path="/login" render={() => (<Login cookies={this.props.cookies}/>)}/>
                                <Route path="/" render={() => <div className="background">
                                    <Navigation/>
                                    <section className="section">
                                        <div className="container">
                                            <Switch>
                                                <PrivateRoute exact path="/" component={Dashboard} />
                                                <PrivateRoute path="/applications" component={Applications}/>
                                                <PrivateRoute path="/showcases" component={Showcases}/>
                                                <PrivateRoute path="/objects" component={Objects}/>
                                                <PrivateRoute path="/media" component={Media}/>
                                                <PrivateRoute path="/interfacetexts" component={InterfaceTexts}/>
                                                <PrivateRoute path="/organizations" component={Organizations}/>
                                            </Switch>
                                        </div>
                                    </section>
                                    <footer className="footer">
                                        <div className="content has-text-centered">
                                            <p>
                                                <strong>CREATE.eu</strong> &copy; 2020
                                            </p>
                                        </div>
                                    </footer>
                                </div>}/>
                            </Switch>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
            </CookiesProvider>
        );
    }
}

export default withCookies(withTranslation()(App));
