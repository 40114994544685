export default class Object {
    constructor(model) {
        this.setDefault();
        this.setModel(model);
    }

    setDefault() {
        this.id = null;
        this.internal = null;
        this.title = {};
        this.description = {};
        this.creator = {};
        this.dating = {};
        this.material = {};
        this.mediaLinks = [];
        this.isTop = false;
        this.isOnLoan =  false;
        this.published = true;
        this.showcaseId = null;
        this.organizationId = null;
        this.showcase = null;
        this.alwaysPublished = true;
        this.startPublishingDate = null;
        this.endPublishingDate = null;
        this.link = null;
        this.inventory = null;
        this.objects = [];
    }

    setModel(model) {
        if (model) {
            this.id = model.id;
            this.internal = model.internal;
            this.title = model.title;
            this.description = model.description;
            this.creator = model.creator;
            this.dating = model.dating;
            this.material = model.material;
            this.mediaLinks = model.mediaLinks;
            this.isTop = model.isTop;
            this.isOnLoan = model.isOnLoan;
            this.published = model.published;
            this.showcaseId = model.showcaseId;
            this.organizationId = model.organizationId;
            this.showcase = model.showcase;
            this.alwaysPublished = model.alwaysPublished;
            this.startPublishingDate = model.startPublishingDate;
            this.endPublishingDate = model.endPublishingDate;
            this.link = model.link;
            this.inventory = model.inventory;
            this.objects = model.objects;
        }
    }
}
