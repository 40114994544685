import { createSelector } from "reselect";
import { createMatchSelector } from "connected-react-router";
import Application from "~app/models/application";

const viewRoute = "/applications/:applicationId";
const viewMatch = createMatchSelector(viewRoute);
const applicationIdSelector = (state) => viewMatch(state)?.params.applicationId;

const createRoute = "/applications/create";
const createMatch = createMatchSelector(createRoute);
const applicationCreateSelector = (state) => createMatch(state)?.isExact;

export const getApplications = (state) => state.applications.models;

export const getCurrentApplication = createSelector(
    [getApplications, applicationIdSelector, applicationCreateSelector],
    (applications, applicationId, applicationCreate) => {
        if (applicationCreate) {
            return new Application();
        }
        return applications.find((application) => application.id === applicationId);
    },
);
