import Actions from "~app/constants/actions";
import API from "~app/modules/api";
import Application from "~app/models/application";
import axios from "axios";

export const queryApplications = () => async (dispatch, getState) => {
    try {
        const { search, skip, limit } = getState().applications;
        const result = await API.QueryApplications(search, skip, limit);
        dispatch({
            type: Actions.Applications.Query,
            payload: {
                totalCount: result.data.metadata.totalCount,
                count: result.data.metadata.count,
                models: result.data.results.map((item) => new Application(item)),
            },
        });
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Applications.Query),
            payload: err,
        });
    }
};

export const paginateApplications = (skip, limit = 20) => async (dispatch) => {
    dispatch({
        type: Actions.Applications.Paginate,
        payload: {
            skip,
            limit,
        },
    });
    dispatch(queryApplications());
};

export const searchApplications = (search) => (dispatch) => {
    dispatch({
        type: Actions.Applications.Search,
        payload: {
            search,
        },
    });
    dispatch(paginateApplications(0));
    dispatch(queryApplications());
};

export const updateApplication = (application) => async (dispatch) => {
    try {
        const data = {
            application,
        };
        const result = await axios.post(`/api/v1/applications/${application.id}`, data);
        const model = new Application(result.data);
        dispatch({
            type: Actions.Applications.Update,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Applications.Update),
            payload: err,
        });
    }
};

export const createApplication = (application) => async (dispatch) => {
    try {
        const data = {
            application,
        };
        const result = await axios.post("/api/v1/applications", data);
        const model = new Application(result.data);
        dispatch({
            type: Actions.Applications.Create,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Applications.Create),
            payload: err,
        });
    }
};

export const deleteApplication = (application) => async(dispatch) => {
    try {
        const result = await axios.delete(`/api/v1/applications/${application.id}`);
        dispatch({
            type: Actions.Applications.Delete,
            payload: result.data,
        });
        dispatch(queryApplications());
        return true;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Applications.Delete),
            payload: err,
        });
        return false;
    }
};
