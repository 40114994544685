import Actions from "~app/constants/actions";
import API from "~app/modules/api";
import Organization from "~app/models/organization";
import axios from "axios";

export const queryOrganizations = () => async (dispatch, getState) => {
    try {
        const { search, skip, limit } = getState().organizations;
        const result = await API.QueryOrganizations(search, skip, limit);
        dispatch({
            type: Actions.Organizations.Query,
            payload: {
                totalCount: result.data.metadata.totalCount,
                count: result.data.metadata.count,
                models: result.data.results.map((item) => new Organization(item)),
            },
        });
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Organizations.Query),
            payload: err,
        });
    }
};

export const paginateOrganizations = (skip, limit = 20) => async (dispatch) => {
    dispatch({
        type: Actions.Organizations.Paginate,
        payload: {
            skip,
            limit,
        },
    });
    dispatch(queryOrganizations());
};

export const searchOrganizations = (search) => (dispatch) => {
    dispatch({
        type: Actions.Organizations.Search,
        payload: {
            search,
        },
    });
    dispatch(paginateOrganizations(0));
    dispatch(queryOrganizations());
};

export const updateOrganization = (organization) => async (dispatch) => {
    try {
        const data = {
            organization,
        };
        const result = await axios.post(`/api/v1/organizations/${organization.id}`, data);
        const model = new Organization(result.data);
        dispatch({
            type: Actions.Organizations.Update,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Organizations.Update),
            payload: err,
        });
    }
};

export const createOrganization = (organization) => async (dispatch) => {
    try {
        const data = {
            organization,
        };
        const result = await axios.post("/api/v1/organizations", data);
        const model = new Organization(result.data);
        dispatch({
            type: Actions.Organizations.Create,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Organizations.Create),
            payload: err,
        });
    }
};

