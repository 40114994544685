import React, {Component} from "react";

const getDownloadLink = (uri) => {
    return `${process.env.ASSETS_CDN}/${uri}`;
};

function Downloadable(WrappedComponent) {
    class ForwardComponent extends Component {
        render() {
            const {forwardedRef, ...restProps} = this.props;
            return <WrappedComponent ref={forwardedRef} getDownloadLink={getDownloadLink} {...restProps} />;
        }
    }

    return React.forwardRef((props, ref) => {
        return <ForwardComponent {...props} forwardedRef={ref}/>;
    });
}

export default Downloadable;
