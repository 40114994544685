import { createReducer } from "@reduxjs/toolkit";
import Actions from "~app/constants/actions";
import ModelReducer from "~app/modules/model-reducer";

const initialState = {
    models: [],
    skip: null,
    limit: null,
    search: null,
    totalCount: null,
    count: null,
};

const organizationsReducer = createReducer(initialState, {
    [Actions.Organizations.Query]: ModelReducer.QueryModels(),
    [Actions.Organizations.Search]: ModelReducer.SearchModels(),
    [Actions.Organizations.Paginate]: ModelReducer.PaginateModels(),
    [Actions.Organizations.Update]: ModelReducer.UpdateModel(),
    [Actions.Organizations.Create]: ModelReducer.CreateModel(),
    [Actions.Organizations.LinkMedia]: ModelReducer.LinkMedia(),
    [Actions.Organizations.UnlinkMedia]: ModelReducer.UnlinkMedia(),
});

export default organizationsReducer;
