import React, {Component} from "react";
import {Button, PageHeader, Row, Statistic, Tabs} from "antd";
import v from "voca";

const {TabPane} = Tabs;

class ListHeader extends Component {
    state = {
        routes: []
    };

    componentDidUpdate(prevProps, prevState) {
        const {plural} = this.props;
        if (prevProps.plural !== plural) {
            this.setState({
                routes: [
                    {
                        path: "/" + plural,
                        breadcrumbName: v.titleCase("plural"),
                    },
                ]
            });
        }
    }

    render() {
        const {routes} = this.state;
        const {singular, plural, count, showImport, showExport, showCreate, onCreate} = this.props;
        const buttons = [];
        if(showCreate){
            buttons.push(<Button key="create" icon="plus"  className="create-button" onClick={onCreate}>Create {v.titleCase(plural)} Item</Button>);
        }
        if (showImport) {
            buttons.push(<Button key="import" icon="import" disabled>Import {v.titleCase(plural)}</Button>);
        }
        if (showExport) {
            buttons.push(<Button key="export" icon="export" disabled>Export {v.titleCase(plural)}</Button>);
        }
        return (
            <PageHeader
                title={v.titleCase(plural)}
                breadcrumb={{routes}}
                style={{
                    border: "1px solid rgb(240, 240, 240)",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
                extra={buttons}
                footer={
                    <Tabs defaultActiveKey="list">
                        <TabPane tab="List View" key="list"/>
                        <TabPane tab="Grid View" key="grid" disabled={true}/>
                    </Tabs>
                }
            >
                <Row type="flex">
                    <Statistic title={"Number Of " + v.titleCase(plural)} value={count}/>
                </Row>
            </PageHeader>
        );
    }
}

ListHeader.defaultProps = {
    showCreate: true,
    showImport: true,
    showExport: true,
};

export default ListHeader;
