export default class InterfaceText {
    constructor(model) {
        this.key = null;
        this.value = {};
        if (model) {
            this.key = model.key;
            this.value = model.value;
        }
    }
}
