import React, { Component } from "react";
import { Avatar, Button, Card, Col, Descriptions, Form, Icon, Input, PageHeader, Row, Tabs, Upload } from "antd";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import Imagable from "~app/hoc/imagable";
import { getLanguages } from "~app/selectors/base";
import { queryLanguages } from "~app/actions/languages";
import styled from "styled-components";
import { getCurrentMedium } from "~app/selectors/media";
import { createMedium, getMedium, updateMedium } from "~app/actions/media";
import ReactPlayer from "react-player";
import axios from "axios";

const { TabPane } = Tabs;

const UploadButton = styled(Button)`
  width: 300px;
  margin-top:20px;
`;

class View extends Component {
    constructor(props) {
        super(props);
        this.handleFile = this.handleFile.bind(this);
        this.goBack = this.goBack.bind(this);
        this.save = this.save.bind(this);
        this.state = { uri: null };
    }

    async componentDidMount() {
        this.props.getMedium(),
        this.props.queryLanguages();
    }

    handleFile(file) {
        console.log(file);
        if (file.response) {
            this.setState({ uri: file.response.uri });
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    save() {
        const { uri } = this.state;
        const { medium, form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const data = {
                    ...medium,
                    name: values.name,
                    description: values.description,
                    copyright: values.copyright,
                    key: values.key,
                    uri: uri || medium.uri,
                };
                if (medium.id) {
                    this.props.updateMedium(data);
                } else {
                    this.props.createMedium(data);
                }
                this.goBack();
            }
        });
    }

    render() {
        const { translation, getImage, resizeImage, languages, form, medium } = this.props;
        const { getFieldDecorator } = form;
        const { uri } = this.state;
        const routes = medium && [
            {
                key: "media",
                path: "/media",
                breadcrumbName: "Media",
            },
            {
                key: "medium",
                path: "/media/" + medium.id,
                breadcrumbName: translation(medium.name) || medium.key,
            },
        ] || [];

        return (
            medium &&
            <>
                <PageHeader
                    style={{
                        border: "1px solid rgb(240, 240, 240)",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                    ghost={false}
                    onBack={() => window.history.back()}
                    title={translation(medium.name) || medium.key}
                    breadcrumb={{ routes }}
                >
                    <Descriptions size="small">
                        <Descriptions.Item label="Published"><Icon type="eye"/></Descriptions.Item>
                        <Descriptions.Item label="Last Modified By">-</Descriptions.Item>
                        <Descriptions.Item label="Last Modified On">-</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
                <Row gutter={[0, 40]}>
                    <Col span={6}>
                        {medium.type !== "video" && <Avatar shape="square" size={300} src={resizeImage(uri || medium.uri, 300, 300)}/>}
                        {medium.type === "video" && <ReactPlayer url={getImage(uri || medium.uri)} width={300} height={300} controls={true}/>}
                        <Upload action="/api/v1/media/upload" onChange={(e) => this.handleFile(e.file)}
                                showUploadList={false}>
                            <UploadButton>
                                <Icon type="upload"/> Upload Media Item
                            </UploadButton>
                        </Upload>
                    </Col>
                    <Col span={17} offset={1}>
                        <Form layout="vertical">
                            <Tabs defaultActiveKey="nl-BE" animated={false}>
                                {languages.map(language =>
                                    <TabPane tab={language.name} key={language.key}>
                                        <Card>
                                            <Form.Item label="Name">
                                                {getFieldDecorator("name[" + language.key + "]", {
                                                    initialValue: translation(medium.name, language.key),
                                                    rules: [{
                                                        required: language.key === "nl-BE",
                                                        message: "Name is a required field for the Dutch language.",
                                                    }],
                                                })(
                                                    <Input placeholder="Name"/>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Description">
                                                {getFieldDecorator("description[" + language.key + "]", {
                                                    initialValue: translation(medium.description, language.key),
                                                })(
                                                    <Input placeholder="Description"/>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Copyright">
                                                {getFieldDecorator("copyright[" + language.key + "]", {
                                                    initialValue: translation(medium.copyright, language.key),
                                                })(
                                                    <Input placeholder="Copyright"/>
                                                )}
                                            </Form.Item>
                                        </Card>
                                    </TabPane>)}
                            </Tabs>
                            <Form.Item label="Key" className="mt20">
                                {getFieldDecorator("key", {
                                    initialValue: medium.key,
                                })(
                                    <Input placeholder="Key"/>
                                )}
                            </Form.Item>
                        </Form>
                        <div className="mt20 buttons-group" style={{ float: "right" }}>
                            <Button icon="rollback" onClick={this.goBack}>Cancel</Button>
                            <Button type="primary" icon="save" onClick={this.save}>Save Medium</Button>
                        </div>
                    </Col>
                </Row>
            </> || <>Error</>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        medium: getCurrentMedium(state),
        languages: getLanguages(state),
    };
};

const mapDispatchToProps = {
    queryLanguages,
    getMedium,
    updateMedium,
    createMedium,
};

const connectStore = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(connectStore, Imagable, Translatable, Form.create())(View);
