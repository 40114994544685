import React, { Component } from "react";
import { connect } from "react-redux";
import { queryLanguages } from "~app/actions/languages";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

const defaultKey = "nl-BE";

const translation = function (translatable, languageKey = i18next.language, defaultValue = null) {
    if (translatable) {
        if (translatable[languageKey]) {
            return translatable[languageKey];
        }
        if (translatable[defaultKey]) {
            return translatable[defaultKey];
        }
    }
    return defaultValue;
};

const mapStateToProps = state => {
    return {
        languages: state.languages.models,
        currentLanguage: state.currentLanguage,
    };
};

const onChange = function onChange(objectName, key, value, language) {
    const { [objectName]: object } = this.state;
    if (!Array.isArray(key)) {
        if (language) {
            object[key][language] = value;
        } else {
            object[key] = value;
        }
        this.setState({
            [objectName]: object,
        });
        return;
    }
    const property = object[key[0]];
    if (language) {
        property[key[1]][language] = value;
    } else {
        property[key[1]] = value;
    }
    this.setState({
        [objectName]: object,
    });
};

function Translatable(WrappedComponent) {
    class ForwardComponent extends Component {
        async componentDidMount() {
            this.props.queryLanguages();
            this.goBack = this.goBack.bind(this);
        }

        goBack() {
            this.props.history.goBack();
        };

        render() {
            const { forwardedRef, ...restProps } = this.props;
            return <WrappedComponent currentTranslation={translation} translation={translation} onChange={onChange} goBack={this.goBack} ref={forwardedRef} {...restProps} />;
        }
    }

    const ConnectedComponent = connect(mapStateToProps, {
        queryLanguages,
    })(withTranslation()(ForwardComponent));

    return React.forwardRef((props, ref) => {
        return <ConnectedComponent {...props} forwardedRef={ref}/>;
    });
}

export default Translatable;
