import { createReducer } from "@reduxjs/toolkit";
import Actions from "~app/constants/actions";
import ModelReducer from "~app/modules/model-reducer";

const initialState = {
    models: [],
};

const mediaGroupsReducer = createReducer(initialState, {
    [Actions.MediaGroups.Query]: ModelReducer.QueryModels(),
});

export default mediaGroupsReducer;
