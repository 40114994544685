import { createReducer } from "@reduxjs/toolkit";
import Actions from "~app/constants/actions";
import ModelReducer from "~app/modules/model-reducer";

const initialState = {
    models: [],
    skip: null,
    limit: null,
    search: null,
    totalCount: null,
    count: null,
};

const applicationsReducer = createReducer(initialState, {
    [Actions.Applications.Query]: ModelReducer.QueryModels(),
    [Actions.Applications.Search]: ModelReducer.SearchModels(),
    [Actions.Applications.Paginate]: ModelReducer.PaginateModels(),
    [Actions.Applications.Update]: ModelReducer.UpdateModel(),
    [Actions.Applications.Create]: ModelReducer.CreateModel(),
});

export default applicationsReducer;
