import axios from "axios";
import _ from "lodash";
import Actions from "~app/constants/actions";

export const queryLanguages = () => _.throttle(async (dispatch) => {
    try {
        const result = await axios.get("/api/v1/languages");
        dispatch({
            type: Actions.Languages.Query,
            payload: { models: result.data },
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: Actions.Error(Actions.Languages.Query),
            payload: err,
        });
    }
}, 100);

export const changeCurrentLanguage = (language) => _.debounce(async (dispatch, language) => {
    dispatch({
        type: Actions.Languages.ChangeCurrentLanguage,
        payload: language,
    });
}, 100);
