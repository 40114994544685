import { createSelector } from "reselect";
import { createMatchSelector } from "connected-react-router";
import Showcase from "~app/models/showcase";

const viewRoute = "/showcases/:showcaseId";
const viewMatch = createMatchSelector(viewRoute);
const showcaseIdSelector = (state) => viewMatch(state)?.params.showcaseId;

const createRoute = "/showcases/create";
const createMatch = createMatchSelector(createRoute);
const showcaseCreateSelector = (state) => createMatch(state)?.isExact;

export const getShowcases = (state) => state.showcases.models;

export const getCurrentShowcase = createSelector(
    [getShowcases, showcaseIdSelector, showcaseCreateSelector],
    (showcases, showcaseId, showcaseCreate) => {
        if (showcaseCreate) {
            return new Showcase();
        }
        return showcases.find((showcase) => showcase.id === showcaseId);
    },
);
