import React, { Component } from "react";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import { withTranslation } from "react-i18next";
import { Button, Table } from "antd";
import v from "voca";
import { Link } from "react-router-dom";

const { Column } = Table;

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interfacetexts: [],
        };
    }

    async componentDidMount() {
        this.setState({
            interfacetexts: await List.getInterfaceTexts(),
        });
    }

    static async getInterfaceTexts() {
        const result = await axios.get("/api/v1/interfacetexts");
        return result.data;
    }

    render() {
        const { t, i18n } = this.props;
        const { interfacetexts } = this.state;
        return (
            <>
                <Table dataSource={interfacetexts} rowKey={interfacetext => interfacetext.id}>
                    <Column title="Key" key="key" render={interfacetext => interfacetext.key}/>
                    <Column title="Value" render={interfacetext => interfacetext.value[i18n.language || "nl-BE"]}/>
                    <Column key="actions" title={v.titleCase(t("action_plural"))} render={interfacetext => <><Link
                        to={{
                            pathname: `/interfacetexts/${interfacetext.id}`,
                            state: {
                                interfacetext,
                            },
                        }}><Button icon="edit"/></Link></>}/>
                </Table>
                <Link
                    to={{
                        pathname: "/interfacetexts/create",
                    }}
                >
                    <Button icon="plus">{v.titleCase(t("interfacetext_new"))} </Button>
                </Link></>
        );
    }
}

export default withTranslation()(List);
