import axios from "axios";

const BASE_URI = "/api/v1";

class API {
    static async QueryShowcases(query = null, skip = null, limit = null) {
        return axios.get(`${BASE_URI}/showcases`, {
            params: {
                q: query,
                skip,
                limit
            }
        });
    }

    static async QueryObjects(query = null, skip = null, limit = null) {
        return axios.get(`${BASE_URI}/showcases/objects`, {
            params: {
                q: query,
                skip,
                limit
            }
        });
    }

    static async QueryOrganizations(query = null, skip = null, limit = null) {
        return axios.get(`${BASE_URI}/organizations`, {
            params: {
                q: query,
                skip,
                limit
            }
        });
    }

    static async QueryApplications(query = null, skip = null, limit = null) {
        return axios.get(`${BASE_URI}/applications`, {
            params: {
                q: query,
                skip,
                limit
            }
        });
    }

    static async QueryMedia(query = null, skip = null, limit = null) {
        return axios.get(`${BASE_URI}/media`, {
            params: {
                q: query,
                skip,
                limit
            }
        });
    }

    static async QueryInterfaceTexts(query = null, skip = null, limit = null) {
        return axios.get(`${BASE_URI}/interfacetexts`, {
            params: {
                q: query,
                skip,
                limit
            }
        });
    }
}

export default API;
