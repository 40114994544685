import React, {Component} from "react";
import {DndProvider, DragSource, DropTarget} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

let draggingIndex = -1;

class BodyRow extends React.Component {
    render() {
        const {isOver, connectDragSource, connectDropTarget, moveRow, ...restProps} = this.props;
        const style = {...restProps.style, cursor: "move"};

        let {className} = restProps;
        if (isOver) {
            if (restProps.index > draggingIndex) {
                className += " drop-over-downward";
            }
            if (restProps.index < draggingIndex) {
                className += " drop-over-upward";
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style}/>),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        draggingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }
        props.moveRow(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};


const DraggableRow = DropTarget("row", rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource("row", rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);

const tableComponents = {
    body: {
        row: DraggableRow,
    }
};

function Sortable(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
        }


        moveRow = (dragIndex, hoverIndex) => {
            const {models} = this.props;
            const model = models[dragIndex];
            const sortedModels = [...models];
            sortedModels.splice(dragIndex, 1);
            sortedModels.splice(hoverIndex, 0, model);
            this.props.onSort(sortedModels.map((model) => model.id));
        };

        render() {
            return (
                <DndProvider backend={HTML5Backend}>
                    <WrappedComponent isSortable={true} {...this.props} onRow={(model, index) => ({
                        index,
                        moveRow: this.moveRow,
                    })}
                                      components={tableComponents}
                    />
                </DndProvider>
            );
        }
    };
}

export default Sortable;
