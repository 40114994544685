import React, { Component } from "react";
import axios from "axios";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { Form, Input, Select, Tabs } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import v from "voca";
import Translatable from "~app/hoc/translatable";
import { withRouter } from "react-router";
import compose from "lodash/fp/compose";
import WithMediaLinks from "~app/hoc/medialinks";
import Imagable from "~app/hoc/imagable";
import SortableTableTransfer from "~app/components/sortabletabletransfer";
import { connect } from "react-redux";
import { getCurrentApplication } from "~app/selectors/applications";
import { getShowcases } from "~app/selectors/showcases";
import { createApplication, queryApplications, updateApplication } from "~app/actions/applications";
import { queryShowcases } from "~app/actions/showcases";

const { Option } = Select;
const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const leftShowcaseColumns = (t, translation) => [
    {
        title: v.titleCase(t("name_internal")),
        key: "internal",
        render: showcase => showcase.internal,
    },
    {
        title: v.titleCase(t("application")),
        key: "application",
        render: showcase => (
            showcase.application && showcase.application.internal || "-"),
    },
];
const rightShowcaseColumns = (t, translation) => [
    {
        title: v.titleCase(t("name_internal")),
        key: "internal",
        render: showcase => {
            return <Link to={"/showcases/" + showcase.id}>{showcase.internal}</Link>;
        },
    },
];

class Edit extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.onShowcaseIdsChange = this.onShowcaseIdsChange.bind(this);
        this.onShowcasesSort = this.onShowcasesSort.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    async componentDidMount() {
        this.props.queryApplications();
        this.props.queryShowcases();
    }

    async onShowcaseIdsChange(showcaseIds) {
        const { application } = this.props;
        console.log("app showcaseIds: "+application.showcaseIds);
        console.log("showcaseIds:"+showcaseIds);
        const data = {
            ...application,
            showcaseIds,
        };
        if (application.id) {
            await this.props.updateApplication(data);
        } else {
            await this.props.createApplication(data);
        }
        await this.props.queryShowcases();
        await this.props.queryApplications();
    }

    async save() {
        const { form, goBack } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { application } = this.props;
                const data = {
                    ...application,
                    internal: values.internal,
                };
                if (application.id) {
                    this.props.updateApplication(data);
                } else {
                    this.props.createApplication(data);
                }
                goBack();
            }
        });
    }

    async onShowcasesSort(showcaseIds) {
        for (const showcaseId of showcaseIds) {
            const data = {
                showcase: {
                    position: showcaseIds.indexOf(showcaseId),
                },
            };
            await axios.patch(`/api/v1/showcases/${showcaseId}`, data);
        }
        this.props.queryApplications();
    }

    render() {
        const { t, form, translation, application, showcases } = this.props;
        const { getFieldDecorator } = form;
        return (
            application
            && (
                <div>
                    <Form {...formItemLayout}>
                        <h1 className="title">{application.internal || "-"}</h1>
                        <Form.Item label={v.titleCase(t("name_internal"))}>
                            {getFieldDecorator("internal", {
                                initialValue: application.internal,
                                rules: [
                                    {
                                        required: true,
                                        message: v.capitalize(t("error_required")),
                                    },
                                ],
                            })(
                                <Input placeholder={v.titleCase(t("name_internal"))}/>
                            )}
                        </Form.Item>
                        <div className="mt20">
                            <div className="field">
                                <label className="label">{v.titleCase(t("showcase_plural"))}</label>
                                {application.showcases &&
                                <SortableTableTransfer
                                    models={application.showcases}
                                    onSort={this.onShowcasesSort}
                                    dataSource={showcases}
                                    targetKeys={application.showcaseIds}
                                    onChange={this.onShowcaseIdsChange}
                                    leftColumns={leftShowcaseColumns(t, translation)}
                                    rightColumns={rightShowcaseColumns(t, translation)}
                                    rowKey={showcase => showcase.id}
                                    titles={[v.titleCase(t("showcase_unselected_plural")), v.titleCase(t("showcase_selected_plural"))]}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return translation(option.name)
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) || option.internal && option.internal.toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                />}
                            </div>
                        </div>
                        <div className="field is-grouped mt20">
                            <div className="control">
                                <button className="button is-success" onClick={this.save}>{v.titleCase(t("save"))}</button>
                            </div>
                            <div className="control">
                                <button className="button" onClick={this.goBack}>{v.titleCase(t("cancel"))}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            ) || <></>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        application: getCurrentApplication(state),
        showcases: getShowcases(state),
    };
};

const mapDispatchToProps = {
    queryApplications,
    queryShowcases,
    updateApplication,
    createApplication,
};

const connectStore = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(connectStore, WithMediaLinks, Imagable, Translatable, withRouter, Form.create({ name: "edit" }))(Edit);
