import { createSelector } from "reselect";
import { createMatchSelector } from "connected-react-router";
import ShowcaseObject from "~app/models/object";

const viewRoute = "/objects/:objectId";
const viewMatch = createMatchSelector(viewRoute);
const objectIdSelector = state => viewMatch(state)?.params.objectId;

const createRoute = "/objects/create";
const createMatch = createMatchSelector(createRoute);
const objectCreateSelector = state => createMatch(state)?.isExact;

export const getObjects = state => state.objects.models;

export const getCurrentObject = createSelector(
    [getObjects, objectIdSelector, objectCreateSelector],
    (objects, objectId, objectCreate) => {
        if (objectCreate){
            return new ShowcaseObject();
        }
        return objects.find(object => object.id === objectId);
    },
);
