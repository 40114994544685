import {createSelector} from "reselect"
import {createMatchSelector} from "connected-react-router";
import Organization from "~app/models/organization";

const viewRoute = "/organizations/:organizationId";
const viewMatch = createMatchSelector(viewRoute);
const createRoute = "/organizations/create";
const createMatch = createMatchSelector(createRoute);
const organizationIdSelector = state => viewMatch(state)?.params.organizationId;
const organizationCreateSelector = state => createMatch(state)?.isExact;
export const getOrganizations = state => state.organizations.models;

export const getCurrentOrganization = createSelector(
    [getOrganizations, organizationIdSelector, organizationCreateSelector],
    (organizations, organizationId, organizationCreate) =>
    {
        if(organizationCreate){
            return new Organization();
        }
        return organizations.find(organization => organization.id === organizationId)
    },
);
