import Actions from "~app/constants/actions";
import API from "~app/modules/api";
import Showcase from "~app/models/showcase";
import _ from "lodash";
import axios from "axios";
import { queryApplications } from "~app/actions/applications";

export const queryShowcases = () => async (dispatch, getState) => {
    try {
        const {search, skip, limit} = getState().showcases;
        const result = await API.QueryShowcases(search, skip, limit);
        dispatch({
            type: Actions.Showcases.Query,
            payload: {
                totalCount: result.data.metadata.totalCount,
                count: result.data.metadata.count,
                models: result.data.results.map((item) => new Showcase(item)),
            },
        });
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Showcases.Query),
            payload: err,
        });
    }
};

export const paginateShowcases = (skip, limit = 20) => async (dispatch) => {
    dispatch({
        type: Actions.Showcases.Paginate,
        payload: {
            skip,
            limit,
        },
    });
    dispatch(queryShowcases());
};

export const searchShowcases = (search) => (dispatch) => {
    dispatch({
        type: Actions.Showcases.Search,
        payload: {
            search,
        },
    });
    dispatch(paginateShowcases(0));
    dispatch(queryShowcases());
};

export const updateShowcase = (showcase) => async (dispatch) => {
    try {
        const data = {
            showcase,
        };
        const result = await axios.post(`/api/v1/showcases/${showcase.id}`, data);
        const model = new Showcase(result.data);
        dispatch({
            type: Actions.Showcases.Update,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Showcases.Update),
            payload: err,
        });
    }
};

export const createShowcase = (showcase) => async (dispatch) => {
    try {
        const data = {
            showcase,
        };
        const result = await axios.post("/api/v1/showcases", data);
        const model = new Showcase(result.data);
        dispatch({
            type: Actions.Showcases.Create,
            payload: { model },
        });
        return model;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Showcases.Create),
            payload: err,
        });
    }
};

export const deleteShowcase = (showcase) => async(dispatch) => {
    try {
        const result = await axios.delete(`/api/v1/showcases/${showcase.id}`);
        dispatch({
            type: Actions.Showcases.Delete,
            payload: result.data,
        });
        dispatch(queryShowcases());
        return true;
    } catch (err) {
        dispatch({
            type: Actions.Error(Actions.Showcases.Delete),
            payload: err,
        });
        return false;
    }
};
