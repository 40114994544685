import * as R from "ramda";

class ModelReducer {
    static QueryModels() {
        return (state, action) => {
            const {
                models, totalCount, count,
            } = action.payload;
            return {
                ...state,
                models,
                totalCount,
                count,
            };
        };
    }

    static FilterRegions() {
        return (state, action) => {
            const { regionIds } = action.payload;
            return {
                ...state,
                regionIds,
            };
        };
    }

    static FilterTags() {
        return (state, action) => {
            const { tagIds } = action.payload;
            return {
                ...state,
                tagIds,
            };
        };
    }

    static SearchModels() {
        return (state, action) => {
            const { search } = action.payload;
            return {
                ...state,
                search,
            };
        };
    }

    static PaginateModels() {
        return (state, action) => {
            const { skip, limit } = action.payload;
            return {
                ...state,
                skip,
                limit,
            };
        };
    }

    static SortModels() {
        return (state, action) => {
            const { modelIds } = action.payload;
            const models = [...state.models];
            const sorter = (acc, cur) => {
                const to = R.indexOf(cur.id, modelIds);
                acc[to] = cur;
                return acc;
            };
            const sort = R.reduce(sorter, []);
            const sortedModels = sort(models);
            return {
                ...state,
                models: sortedModels,
            };
        };
    }

    static UpdateModel() {
        return (state, action) => {
            try {
                const { model } = action.payload;
                const models = [...state.models];
                const index = models.findIndex((iteratorModel) => iteratorModel.id === model.id);
                if (index !== -1) {
                    models.splice(index, 1, model);
                }
                return {
                    ...state,
                    models
                };
            } catch (err) {
                console.log(err);
            }
            return { ...state };
        };
    }

    static CreateModel() {
        return (state, action) => {
            try {
                const { model } = action.payload;
                const models = [...state.models];
                models.push(model);
                return {
                    ...state,
                    models,
                };
            } catch (err) {
                console.log(err);
            }
            return { ...state };
        };
    }

    static LinkMedia() {
        return (state, action) => {
            const newModels = [...state.models];
            try {
                const model = newModels.find((m) => m.id === action.payload.model.id);
                model.mediaLinks.push({
                    ...action.payload.medium,
                    group: action.payload.group,
                });
            } catch (err) {
                console.log(err);
            }
            return {
                ...state,
                models: newModels,
            };
        };
    }

    static UnlinkMedia() {
        return (state, action) => {
            const newModels = [...state.models];
            try {
                const model = newModels.find((model) => model.id === action.payload.model.id);
                model.mediaLinks = model.mediaLinks.filter((medium) => medium.id !== action.payload.medium.id || medium.group !== action.payload.group);
            } catch (err) {
                console.log(err);
            }
            return {
                ...state,
                models: newModels
            };
        };
    }
}

export default ModelReducer;
