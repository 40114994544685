import React, { Component } from "react";
import { Card, Col, Form, Icon, Input, Row, Tabs } from "antd";
import "react-quill/dist/quill.snow.css";
import v from "voca";
import { connect } from "react-redux";
import Translatable from "~app/hoc/translatable";
import Imagable from "~app/hoc/imagable";
import WithMediaLinks from "~app/hoc/medialinks";
import compose from "lodash/fp/compose";
import { getCurrentOrganization } from "~app/selectors/organizations";
import { queryOrganizations, updateOrganization, createOrganization} from "~app/actions/organizations";
import TextEditor from "~app/components/text-editor";
import { getLanguages } from "~app/selectors/base";

const { TabPane } = Tabs;


class Edit extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
    }

    async componentDidMount() {
        this.props.queryOrganizations();
    }

    async save() {
        const { form, goBack } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { organization } = this.props;
                if(organization.id){
                    this.props.updateOrganization({
                        ...organization,
                        internal: values.internal,
                        name: values.name,
                    });
                }else{
                    this.props.createOrganization({
                        ...organization,
                        internal: values.internal,
                        name: values.name,
                    });
                }
                goBack();
            }
        });
    }

    render() {
        const { t, goBack, form , organization, currentLanguage, languages } = this.props;
        const { getFieldDecorator } = form;
        return (
            <>
                {organization && (
                    <Form>
                        <h1 className="title">{organization.internal}</h1>
                        <Card title={v.titleCase(t("property_plural"))}>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={v.titleCase(t("name_internal"))}>
                                        {getFieldDecorator("internal", {
                                            initialValue: organization.internal,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: v.capitalize(t("error_required")),
                                                },
                                            ],
                                        })(
                                            <Input placeholder={v.titleCase(t("name_internal"))}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Tabs defaultActiveKey={currentLanguage} animated={false} className="mt20">
                            {languages.map(language => (
                                <TabPane tab={language.name} key={language.key}>
                                    <Card title={v.titleCase(t("content"))}>
                                        <Form.Item label={v.titleCase(t("title"))}>
                                            {getFieldDecorator("name[" + language.key + "]", {
                                                initialValue: organization.name[language.key],
                                            })(
                                                <Input placeholder={v.titleCase(t("name"))}/>
                                            )}
                                        </Form.Item>
                                    </Card>
                                </TabPane>
                            ))}
                        </Tabs>
                        <div className="field is-grouped is-pulled-right mt20">
                            <div className="control">
                                <button className="button is-success" onClick={this.save}>{v.titleCase(t("save"))}</button>
                            </div>
                            <div className="control">
                                <button className="button" onClick={goBack}>{v.titleCase(t("cancel"))}</button>
                            </div>
                        </div>
                    </Form>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        languages: getLanguages(state),
        organization: getCurrentOrganization(state),
    };
};

export default compose(WithMediaLinks, Imagable, Translatable, Form.create({ name: "edit" }), connect(mapStateToProps, {
    queryOrganizations,
    updateOrganization,
    createOrganization,
}))(Edit);
