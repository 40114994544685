import React, {Component} from "react";
import PropTypes from "prop-types";
import {Input} from "antd";

const {Search: SearchComponent} = Input;

class Search extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        this.props.onChange(value);
    }

    render() {
        const {defaultValue} = this.props;
        return <SearchComponent placeholder="Search" onChange={e => this.onChange(e.target.value)} defaultValue={defaultValue} allowClear={true} />;
    }
}

Search.defaultProps = {
    defaultValue: null,
};

Search.propTypes = {
    defaultValue: PropTypes.string,
};

export default Search;
