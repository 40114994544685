import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import LinkMulti from "./link-multi";
import { linkMediaToObject, unlinkMediaFromObject } from "~app/actions/objects";

class LinkMultiWrapper extends Component {
    render() {
        const {
       model,
        } = this.props;
        return (
            <>
                <Switch>
                    <Route
                        exact path="/media/link/object/:id/:group"
                        render={(props) => (
                            <LinkMulti
                                {...props} linkAction={this.props.linkMediaToObject}
                                unlinkAction={this.props.unlinkMediaFromObject}
                                model={model}
                            />
                        )}
                    />
                </Switch>
            </>
        );
    }
}

const getModel = (state, modelName, modelId) => {
    switch (modelName) {
    case "object":
        return state.objects.models.find((object) => object.id === modelId);
    default:
        return null;
    }
};

const mapStateToProps = (state, ownProps) => {
    const { model, id } = ownProps.match.params;
    return {
        model: getModel(state, model, id),
    };
};

const connectStore = connect(
    mapStateToProps,
    {
        linkMediaToObject,
        unlinkMediaFromObject,
    },
);

export default compose(connectStore)(LinkMultiWrapper);
